<template>
  <router-link v-slot="{ navigate }" to="/devices" custom>
    <div class="logo d-flex align-baseline px-4">
      <v-img
        class="my-7 clickable"
        max-width="2.5em"
        :src="require('@/assets/images/gem.png')"
        @click="navigate"
      />
      <a class="text-h4 white--text pl-5" @click="navigate">PUCS</a>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "DefaultDrawerHeader",
};
</script>

<style scoped>
.logo a {
  text-transform: uppercase;
  padding: 0.5rem 0;
  text-decoration: none;
  font-weight: 400;
  overflow: hidden;
}
</style>
